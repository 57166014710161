import axios from 'axios';
import { consoleLogger } from 'helpers/analytics/console-logger';
import { ConsoleLoggerCategories, ConsoleLoggerServices, ConsoleType } from 'helpers/types/analytics';
import Auth from 'helpers/utils/cognito';
import { addToCarts } from 'redux/reducers/product-cart';
import { store } from 'redux/store';

/**
 * Retrieves the current user's access token from the authentication session.
 * @returns {Promise<string | null>} The access token as a string, or null if unavailable.
 */
export const getAccessToken = async () => {
  const session = await Auth.getSession();
  return session?.getAccessToken()?.getJwtToken();
};

/**
 * Fetches the active user cart (reservation) ID for a specific storeId from the Jane API, handling pagination internally.
 * Filters only reservations with status "pending" and returns the ID of the first found cart for the store.
 * @param {number} storeId - The store ID to filter pending carts for.
 * @returns {Promise<string|null>} The UUID of the active cart, or null if no cart is found.
 * @throws {Error} If the request fails, an error is thrown with a message.
 */
export const getActiveCartByStoreId = async (storeId) => {
  const accessToken = await getAccessToken();
  if (!accessToken) {
    return null; // Skip for guest users
  }

  try {
    const externalUserApiClient = axios.create({
      baseURL: process.env.NEXT_PUBLIC_IHJ_PROXY_HOST + '/roots/external_user_api/v1',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    let pendingCarts = [];
    let hasMorePages = true;
    let pagination_id = null;

    // Step 1: Loop to get all pending carts
    while (hasMorePages) {
      const params = {
        pageSize: 100,
        'reservation_status[]': 'pending',
        ...(pagination_id && { pagination_id }), // Add pagination_id to params if it's not null
      };
      await consoleLogger({
        category: ConsoleLoggerCategories.API_CALL,
        consoleType: ConsoleType.LOG,
        decision: 'Calling Jane to fetch carts',
        details: {
          service: ConsoleLoggerServices.JANE,
          requestPayload: params,
        },
      });
      const { data } = await externalUserApiClient.get('/carts', {
        params,
      });

      const { reservations } = data?.data || [];
      pendingCarts = pendingCarts.concat(reservations);

      // Check if there are more pages
      hasMorePages = data.hasNextPage;
      pagination_id = data.pagination_id;
    }

    // Step 2: Filter pending carts for the specified storeId
    const filteredCarts = pendingCarts.filter((cart) => cart.store_id === Number(storeId));

    // Step 3: Return the ID of the first cart, if available
    if (filteredCarts.length > 0) {
      return filteredCarts[0].uuid;
    }

    return null; // Return null if no cart is found
  } catch (error) {
    await consoleLogger({
      category: ConsoleLoggerCategories.API_FAILURE,
      consoleType: ConsoleType.ERROR,
      decision: 'Failed to fetch active carts',
      details: {
        service: ConsoleLoggerServices.JANE,
        details: error?.message || error,
      },
    });
    return null;
  }
};

/**
 * Fetches a specific cart by ID from the Jane API.
 * @param {string} cartId - The ID of the cart to fetch.
 * @returns {Promise<Object | null>} The cart data, or null if an error occurs.
 */
export const fetchCartById = async (cartId) => {
  const accessToken = await getAccessToken();
  if (!accessToken) {
    return null;
  }

  try {
    // Create an axios instance for interacting with the Jane API's carts endpoint
    const client = axios.create({
      baseURL: process.env.NEXT_PUBLIC_IHJ_PROXY_HOST + '/roots/carts_api/v1/carts/',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    // Fetch the cart by ID
    await consoleLogger({
      category: ConsoleLoggerCategories.API_CALL,
      consoleType: ConsoleType.LOG,
      decision: `Calling Jane to fetch cart by ID. ID is ${cartId}`,
      details: {
        service: ConsoleLoggerServices.JANE,
      },
    });
    const { data } = await client.get(cartId);

    return data?.data;
  } catch (error) {
    const errorStatus = error?.response?.data?.errors?.status || error?.response?.status;
    // Handle 401 unauthorized
    if (errorStatus === 401) {
      await consoleLogger({
        category: ConsoleLoggerCategories.API_FAILURE,
        consoleType: ConsoleType.ERROR,
        decision: 'Sign out user',
        details: {
          service: ConsoleLoggerServices.JANE,
          errorCode: errorStatus,
          description: 'Received unexpected 401 respons for fetchCartById.',
        },
      });
      Auth.signOut();
    }
    // Log the error and return null instead of throwing an error
    return null;
  }
};

/**
 * Fetches the latest cart by ID from the Jane API and updates the Redux store with the fetched cart data.
 * Dispatches the action to add or update the cart for the corresponding store.
 *
 * @returns {Promise<void>} Resolves when the cart is fetched and Redux is updated.
 * @throws Will log an error if fetching the cart or updating the Redux store fails.
 */
export const updateCartInRedux = async (cart) => {
  try {
    if (cart) {
      const { store_id, cart_uuid, items } = cart;
      store.dispatch(
        addToCarts({
          store_id,
          cart: {
            cart_uuid,
            items,
            discountPrice: 0,
            subTotal: 0,
          },
        }),
      );
    }
  } catch (error) {
    console.error(`[CART] Error updating cart in redux:`, error);
  }
};

/**
 * Syncs the user's current Redux cart with the Jane API.
 * If a cart exists for the store in Redux, it will be updated with the latest data from Jane.
 * If no cart exists in Redux, it will check for an active cart in Jane and update Redux accordingly.
 *
 * @returns {Promise<void>} Resolves when the sync operation completes.
 * @throws Will log any errors encountered during the sync process.
 */
export const syncCarts = async () => {
  const state = store.getState();
  const storeId = state?.dispensaryList?.currentDispensary?.dispensaries?.storeId;

  if (!storeId) {
    return;
  }

  const currentCart = state?.cart?.carts?.[storeId];
  let cartUUID = currentCart?.cart_uuid;

  try {
    // If no current cart in Redux, get active cart by store id.
    if (!cartUUID) {
      const activeCartId = await getActiveCartByStoreId(storeId);
      if (activeCartId) {
        cartUUID = activeCartId;
      }
    }

    // If we have a cart UUID, fetch and update the cart in Redux
    if (cartUUID) {
      const latestCart = await fetchCartById(cartUUID);
      if (latestCart) {
        await updateCartInRedux(latestCart);
      }
    }
  } catch (error) {
    const errorStatus = error?.response?.data?.errors?.status || error?.response?.status;
    // Handle 401 unauthorized
    if (errorStatus === 401) {
      await consoleLogger({
        category: ConsoleLoggerCategories.API_FAILURE,
        consoleType: ConsoleType.ERROR,
        decision: 'Sign out user',
        details: {
          service: ConsoleLoggerServices.JANE,
          errorCode: errorStatus,
          description: 'Received unexpected 401 respons for syncCart.',
        },
      });
      Auth.signOut();
    }
    await consoleLogger({
      category: ConsoleLoggerCategories.API_FAILURE,
      consoleType: ConsoleType.ERROR,
      decision: 'Error syncing carts',
      details: {
        service: ConsoleLoggerServices.JANE,
        details: error?.message || error,
      },
    });
  }
};
